import React, { useState, useEffect } from "react";

// components
import PrivateRoute from "../components/Authentication/PrivateRoute";
import CartMaker from "../components/Appointments/CartMaker";

const CheckoutBuilder = (): React.ReactNode => {
  return (
    <PrivateRoute>
      <CartMaker />
    </PrivateRoute>
  )
}

export default CheckoutBuilder;
